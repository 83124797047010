import React, { Component } from "react";
import AbsaleCard from "./../AbsaleCard";
import {
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col
} from "reactstrap";

var isnotProduct = false;
var selectAProduct = true;

const SCREEN_WIDTH_LIMIT = 1000;

class ProductCard extends Component {
  state = {
    height: window.innerHeight,
    width: window.innerWidth,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      park_id: null,
      product_id: this.props.product_id,
      product_selected: null,
      show_dropdown: false,
      selected_park: false,
      height: window.innerHeight,
      width: window.innerWidth,
    };
    this.handleChangeParks = this.handleChangeParks.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    // this.receiveMessage = this.receiveMessage.bind(this);
  }

  updateDimensions() {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth
    });
  }

  // receiveMessage(event) {
  //   if (event.origin !== 'https://booking.ta-dev.mitija.com') {
  //     return; // Do not process the message
  //   }
  
  //   if (event.data.type === 'myData') {
  //     console.log('Received data:', event.data.data);
  
  //   }
  // }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    // window.addEventListener('message', this.receiveMessage);
  }
  componentDidUpdate(prevProps, prevState) {
    if (isnotProduct) {
      if (prevProps.productSelected) {
        isnotProduct = false
      }
    }

    if (parseInt(this.props.park_id) === 0) {
        selectAProduct = false;
    }
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    this.changeProductSelected(newProps.park_id);
    if (!this.state.product_selected){
      isnotProduct = true
    }
  }
  handleChangeParks(e) {
    this.setState({ park_id: parseInt(e.target.id), selected_park: e.target.innerHTML, show_dropdown: !this.state.show_dropdown});
    this.changeProductSelected(parseInt(e.target.id))
  }

  changeProductSelected(id) {
    let first = true
    let products = this.props.products_all.filter(obj => obj.company_id[0] === parseInt(id))
    if (products.length > 0) {
      isnotProduct = false
      // eslint-disable-next-line
      products.map(prod => {
        if (first) {  
          this.setState({product_selected: prod})
          first = false
        }
      })
    } else {
      isnotProduct = true
      this.setState({product_selected: null})
    }
    
  }
  render() {
    const { numberCircle, cardStyle, cardClass } = this.props;
    let { headCard, cardCollapse } = this.getCardDetail();
    if (!headCard) {
      headCard = (
        <React.Fragment>
          <div className="font-weight-bold">Location</div>
          <div className="text-secondary font-weight-bold" />
        </React.Fragment>
      );
    }

    return (
      <AbsaleCard
        cardStyle={cardStyle}
        numberCircle={numberCircle}
        headCard={headCard}
        step="1"
        cardClass={cardClass}
        cardCollapse={cardCollapse}
        collapse={this.props.ProductCardCollapse}
        data={this.props.data}
        actionCollapse={this.props.actionCollapse}
        name="ProductCardCollapse"
      />
    );
  }

  pushTZDataLayer = (args={}) => {
    // if (process.env.REACT_APP_ENV == 'production') {
      // Push data layer to Tag Manager
      window.tzdataLayer.push(args);
      // Logging the data layer
      console.log(`Push DataLayer: ${args.event}`, window.tzdataLayer);
    // }
  }
  
  productSubmit = (e) => {
    const park_id = this.state.park_id
        ? this.state.park_id
        : this.props.park_id;
    var name = this.props.park_name
    this.props.parks_all.filter(val => val.id === parseInt(park_id)).map(obj => name = this.state.selected_park)
    const other_park = this.props.parks_all.filter((user) => user.name.includes(this.state.selected_park));
    e.preventDefault();
    if (other_park[0].type == 'other_company'){
      let argsDataLayer = { 'event': 'step1', 'step': 'location', 'location': this.state.selected_park };
      this.pushTZDataLayer(argsDataLayer);
      window.location.href = other_park[0].link + '#/order/' + other_park[0].id + '?treetops=' + true
    }
    else {
      if (!isnotProduct) {
        isnotProduct = true;
        this.props.action(this.state.product_selected, name, park_id);
      }
      window.history.pushState(null, '', "/#/order/" + park_id);  
      window.location.reload()
    }
  }
  getCardDetail() {
    if (
      !this.props.parks_all ||
      !this.props.products_all
    )
      return "";

    const parks = this.props.parks_all.map(park_all => {
      return (
        <option
          value={park_all.id}
          key={park_all.id}
        >
          {park_all.name}
        </option>
      );
    });

    parks.unshift(<option
        value={null}
        key={null}
        >
        Select a park
        </option>);

    const list_parks = this.props.parks_all

    const parks2 = list_parks.map((park_all, index) => {
      return (
        <div className="row" key={park_all.id}>
          {
            (index != 0) ?
              (park_all.state != list_parks[index-1].state) ?
                <>
                  <span className={"col-3 col-md-3 ml-2 mt-2 text-secondary font-weight-bold"}>{park_all.state}</span>
                </>
                : 
                <span className={"col-3 col-md-3 ml-2 mt-2 text-secondary font-weight-bold"} style={{opacity:"0"}}>{park_all.state}</span>
            :
            <span className={"col-3 col-md-3 ml-2 mt-2 text-secondary font-weight-bold"}>{park_all.state}</span>
          }
            <span onClick={this.handleChangeParks} id={park_all.id} className="col-8 col-md-8 ml-3 mt-2 park_selection" style={{fontWeight:"bold", padding:"0px"}}>{park_all.name}</span>
        </div>
      );
    });
    
    if (!this.state.park_id) {
      // eslint-disable-next-line
      this.state.park_id = this.props.park_id
    }
    const headCard = (
      <React.Fragment>
        <div className="font-weight-bold title">Location</div>
        <div className="text-secondary font-weight-bold">
          {this.props.park_name}
        </div>
      </React.Fragment>
    );

    const modalPark = (
      <Modal 
          backdrop={true}
          keyboard={false}
          centered={true}
          autoFocus={true}
          zIndex={2000}
          isOpen={this.props.ProductCardCollapse}>
          <ModalBody className="modal-warning">
            <Form
              onSubmit={e => this.productSubmit(e)}>
              <Row style={{margin:"auto", width:"100%",padding:0}}>
                <Col md={12} style={{margin:"auto", width:"100%",padding:0}}>
                  <Row style={{margin:"auto", width:"100%",padding:0}}>
                    <Col sm={12} style={{margin:"auto", width:"100%",padding:0}}>
                      <div className={selectAProduct && isnotProduct ? 'warning-text mb-2' : 'd-none'}>
                        Product for this park is not available, please select to another park.
                      </div>
                    </Col>
                    <Col sm={12} style={{margin:"auto", width:"100%",padding:0}}>
                      <FormGroup>
                      <div id="choose-park__selected" className={`${!this.state.show_dropdown ? 'select__selected park-showing' : 'select__selected'}`} style={{border:"#262b4d solid 1px", cursor:"pointer"}}>
                        <div 
                          onClick={() => {this.setState({ show_dropdown: !this.state.show_dropdown})}}
                          className={"ml-3"}
                        >
                            {this.state.selected_park || this.props.park_name || "Select a Park"}
                        </div>
                      </div>
                      <div 
                        id="choose-park__list"
                        className={`${!this.state.show_dropdown ? 'park-show fade-in' : 'park-collapse'}`}
                        style={{border:"#262b4d solid 1px", borderTop:"0px", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px"}} 
                      >
                        <div className="pt-2"></div>
                        <div className="parks_list">
                          {parks2}
                        </div>
                      </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br/>
              <Button className="btn" type="submit" disabled={selectAProduct && isnotProduct}>
                CONTINUE
              </Button>
            </Form>
          </ModalBody>
      </Modal>
    )
    
    const cardCollapse = (
      <Card>
        <CardBody>
          {this.state.width < SCREEN_WIDTH_LIMIT ? modalPark : ( 
          <Form
            onSubmit={e => this.productSubmit(e)}>
            <Row>
              <Col md={12}>
                <Row>
                  <Col sm={12}>
                    <div className={selectAProduct && isnotProduct ? 'warning-text' : 'd-none'}>
                      Product for this park is not available, please select to another park.
                    </div>
                  </Col>
                  <Col sm={10}>
                    <FormGroup>
                    <div id="choose-park__selected" className={`${!this.state.show_dropdown ? 'select__selected park-showing' : 'select__selected'}`} style={{border:"#262b4d solid 1px", cursor:"pointer"}}>
                      <div 
                        onClick={() => {this.setState({ show_dropdown: !this.state.show_dropdown})}}
                        className={"ml-3"}
                      >
                          {this.state.selected_park || this.props.park_name || "Select a Park"}
                      </div>
                    </div>
                    <div 
                      id="choose-park__list"
                      className={`${!this.state.show_dropdown ? 'park-show fade-in' : 'park-collapse'}`}
                      style={{border:"#262b4d solid 1px", borderTop:"0px", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px"}} 
                    >
                      <div className="pt-2"></div>
                      <div className="parks_list">
                        {parks2}
                      </div>
                    </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br/>
            <Button className="btn" type="submit" disabled={selectAProduct && isnotProduct}>
              CONTINUE
            </Button>
          </Form>
          )}
        </CardBody>
      </Card>
    );

    return { headCard, cardCollapse };
  }
}
export default ProductCard;
